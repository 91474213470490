<template>
  <b-container fluid>

    <vue-headful title="Page not found | FOMS" />

    <b-row align-v="center" align-h="center" class="mt-5">
      <b-col cols="6">
        <div>
          <b-card class="overflow-hidden text-left" >
            <b-row>
              <b-col align-self="center" cols="2">
                <b-card-img src="/favicon.png" class="rounded-0" style="max-width: 100px"></b-card-img>
              </b-col>
              <b-col cols="10">
                <b-card-body title="Field Operations Management System - FOMS">
                  <b-card-text>

                    <div class="clearfix" style="margin-top: 50px">
                        <h1 class="float-left display-3 mr-2">404</h1>
                        <h4 class="pt-1">Oops! You're lost.</h4>
                        <p class="text-muted">The page you are looking for was not found.</p>
                   </div>
    
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
    </b-row>

  </b-container>


</template>

<script>
import Vue from 'vue'
import vueHeadful from 'vue-headful'

Vue.component('vue-headful', vueHeadful)
export default {
  name: 'Page404'
}
</script>
